export type UserInfo = {
	id: string;
	name: string;
	imageUrl?: string;
};

export type UserCreds = { email: string; password: string };

export interface ServerProfile {
	friendStatus: -2 | -1 | 0 | 1 | 2;
	id: string;
	imageURL?: string;
	lname: string;
	name: string;
}

export interface Profile {
	friendStatus:
		| 'requestedThem'
		| 'friend'
		| 'requestedMe'
		| 'blockedMe'
		| 'blockedThem';
	id: string;
	imageUrl?: string;
	lname: string;
	name: string;
}

export const friendStatusConversionMap: {
	[serverStatus: string]: Profile['friendStatus'];
} = {
	'-2': 'blockedThem',
	'-1': 'blockedMe',
	'0': 'requestedThem',
	'1': 'friend',
	'2': 'requestedMe',
};
