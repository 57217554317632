import { useEffect, useMemo } from 'react';
import { useAuthState } from 'Auth/AuthProvider';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import styled from 'styled-components';
import { useSnooState } from 'Snoo/SnooProvider';
import { SnooCategory } from 'Snoo/snooTypes';
import { DateTime, Info } from 'luxon';
import SnooList from 'Snoo/SnooList';

type Props = { selectedCategory: SnooCategory; selectedDate: DateTime };

const currentDate = DateTime.local().startOf('day');

const HomePage = ({ selectedCategory, selectedDate }: Props) => {
	const { userInfo, setUserInfo } = useAuthState();
	const { actions: snooActions, snoosById } = useSnooState();
	if (typeof setUserInfo === 'undefined') {
		throw new Error('useAuthState must be used with an AuthProvider');
	}

	useEffect(() => {
		if (userInfo && snooActions) {
			snooActions.fetchSnoosByUserId(userInfo.id);
		}
	}, [userInfo?.id, !!snooActions]);

	const filteredSnoos = useMemo(
		() =>
			Object.values(snoosById).filter((s) => {
				switch (selectedCategory) {
					case 'upcoming':
						return (
							!s.TBD &&
							s.date.month === selectedDate.month &&
							s.date.year === selectedDate.year &&
							s.date >= currentDate
						);
					case 'past':
						return (
							!s.TBD &&
							s.date.month === selectedDate.month &&
							s.date.year === selectedDate.year &&
							s.date < currentDate
						);
					case 'TBD':
					default:
						return s.TBD;
				}
			}),
		[selectedCategory, selectedDate, snoosById]
	);

	return (
		<HomePageContainer>
			<div className="header">
				<span>{selectedCategory} snoos</span>
				<CalendarIcon />
			</div>
			<SnooList selectedCategory={selectedCategory} snoos={filteredSnoos} />
		</HomePageContainer>
	);
};

export default HomePage;

const HomePageContainer = styled.div`
	width: 770px;
	background-color: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 12px;
	.header {
		width: 100%;
		height: 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 38px;
		background-color: #daefee;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		margin-bottom: 12px;
	}
`;
