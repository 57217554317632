import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import firebase from 'firebase/app';

document.addEventListener('DOMContentLoaded', () => {
	var config = {
		apiKey: 'AIzaSyDgoZCpp6AWpEnhBMRXScPAxVEGv0Oh02Q',
		authDomain: 'snoo-9fd06.firebaseapp.com',
		databaseURL: 'https://snoo-9fd06.firebaseio.com',
		storageBucket: 'snoo-9fd06.appspot.com',
		projectId: 'snoo-9fd06',
	};
	firebase.initializeApp(config);
	const rootEl = document.getElementById('root');
	ReactDOM.render(<Root />, rootEl);
});
