import { ReactComponent as CircleYes } from 'icons/circle-yes.svg';
import { ReactComponent as CircleNo } from 'icons/circle-no.svg';
import { ReactComponent as CircleMaybe } from 'icons/circle-maybe.svg';
import { ReactComponent as ThreeDots } from 'icons/three-dots.svg';
import { Snoo, SnooCategory } from './snooTypes';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const currentDate = DateTime.local().startOf('day');

const constructTimeDifference = (d: DateTime) => {
	return currentDate
		.plus({ days: d.diff(currentDate, 'day').days })
		.toRelativeCalendar({ unit: 'days' });
};

const Row = ({
	index,
	isFirstInMonth,
	selectedCategory,
	snoosByDate,
	snooDates,
}: {
	index: number;
	isFirstInMonth: Function;
	selectedCategory: SnooCategory;
	snoosByDate: {
		[date: number]: Snoo[];
	};
	snooDates: number[];
}) => {
	const snooTimeStamp = snooDates[index];
	const snooDate = DateTime.fromSeconds(snooTimeStamp);

	const snoos = snoosByDate[snooTimeStamp];
	return (
		<>
			{selectedCategory !== 'TBD' && isFirstInMonth(index) && (
				<MonthHeader>
					{snooDate.monthLong} {snooDate.year}
				</MonthHeader>
			)}
			<RowContainer style={{ height: snoos.length * 100 }}>
				{selectedCategory !== 'TBD' && (
					<div className="date">
						<div>{snooDate.toLocaleString({ day: '2-digit' })}</div>
						<div>{snooDate.weekdayShort}</div>
						<div className="difference">
							{constructTimeDifference(snooDate)}
						</div>
					</div>
				)}
				<ul className="snoos">
					{snoos.map((snoo) => (
						<li className="snoo" key={snoo.id}>
							<span className="name-and-setting">
								<Link className="name" to={`/snoos/${snoo.id}`}>{snoo.name}</Link>
								<div className="setting">
									<span>{snoo.where}</span>
									{snoo.where && snoo.time && <span className="dot">•</span>}
									<span>{snoo.time}</span>
								</div>
							</span>
							<span className="rsvp">
								{selectedCategory !== 'past' && snoo.rsvp && <div className="can-you-go"><div>can you go?</div>
									<div className="icons">
										<CircleYes />
										<CircleNo />
										<CircleMaybe />
									</div></div>}
							</span>
							<span className="image-container">
								{snoo.imageURL && <img src={snoo.imageURL} />}
							</span>
							<span className="three-dots">
								<ThreeDots />
							</span>
						</li>
					))}
				</ul>
			</RowContainer>
		</>
	);
};

export default Row;

const RowContainer = styled.div`
    display: flex;
    align-items: center;
    .date, .snoos {
        height: 100%;
    }
	.date {
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Helvetica Neue;
        font-size: 20px;
        border-bottom: 1px solid #E0E0E0;
        padding: 20px 0;
        .difference {
            font-size: 14px;
            color: #bdbdbd;
        }
	}
	.snoos {
        flex: 1;
		display: flex;
		flex-direction: column;
		.snoo {
            height: 100px;
			display: flex;
			justify-content: space-between;
            align-items: center;
            padding: 20px;
            border-bottom: 1px solid #E0E0E0;
            .name-and-setting {
                width: 225px;
                font-family: Helvetica Neue;
                .name {
                    font-size: 18px;
                    color: #3e726f;
					text-decoration: none;
                }
                .setting {
                    font-size: 13px;
                    color: #2c141a;
                    .dot {
                        margin: 0 5px;
                    }
                }
            }
            .rsvp {
				width: 106px;
                text-align: center;
				.can-you-go {
					display: flex;
					flex-direction: column;
					.icons {
						display: flex;
						justify-content: space-between;
						align-items; center;
						margin-top: 10px;
						svg {
							cursor: pointer;
						}
					}
				}
            }
            .image-container {
                width: 90px;
                height: 90px;
                img {
                    height: 100%;
                }
            }
            .three-dots {
                width: 44px;
                height: 44px;
                border-radius: 44px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background-color: rgba(107, 189, 184, 0.51);
                }
            }
		}
	}
`;

const MonthHeader = styled.div`
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	background: rgba(242, 242, 242, 0.4);
	padding-left: 48px;
	font-family: EB Garamond;
	font-style: italic;
	font-weight: 500;
	font-size: 20px;
	border-top: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
`;
