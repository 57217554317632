import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ButterflyIcon } from 'icons/butterfly.svg';
import { ReactComponent as MagnifyingGlassIcon } from 'icons/magnifying-glass.svg';
import { SnooCategory } from 'Snoo/snooTypes';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useAuthState } from 'Auth/AuthProvider';
import { Link, useHistory } from 'react-router-dom';

type Props = {
	selectedCategory: SnooCategory;
	setSelectedCategory: Dispatch<SetStateAction<SnooCategory>>;
};

const Header = ({ selectedCategory, setSelectedCategory }: Props) => {
	const { location, push: redirectTo } = useHistory();
	const { userInfo, setUserInfo } = useAuthState();
	if (typeof setUserInfo === 'undefined') {
		throw new Error('useAuthState must be used with an AuthProvider');
	}

	const [searchQuery, setSearchQuery] = useState<string>('');

	const [popupIsOpen, setPopupIsOpen] = useState<boolean>(false);
	const createButtonClass = (category: SnooCategory) =>
		category === selectedCategory ? 'selected' : '';

	const handleCategoryClick = useCallback((category: SnooCategory) => {
		setSelectedCategory(category);
		if (location.pathname !== '/') redirectTo('/');
	}, []);

	return (
		<HeaderContainer>
			<Link className="icon-container" to="/">
				<ButterflyIcon />
				<i>snoo</i>
			</Link>
			<div className="snoo-categories">
				<button
					className={createButtonClass('upcoming')}
					onClick={() => handleCategoryClick('upcoming')}
				>
					upcoming
				</button>
				<button
					className={createButtonClass('TBD')}
					onClick={() => handleCategoryClick('TBD')}
				>
					TBD
				</button>
				<button
					className={createButtonClass('past')}
					onClick={() => handleCategoryClick('past')}
				>
					past
				</button>
			</div>
			<div className="input-container">
				<input
					placeholder="search snoo"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
				<MagnifyingGlassIcon />
			</div>
			<div className="profile-photo">
				<img
					src={userInfo?.imageUrl}
					onClick={() => setPopupIsOpen(!popupIsOpen)}
				/>
				{popupIsOpen && (
					<ul className="popup">
						<li>view profile</li>
						<li
							onClick={() =>
								firebase
									.auth()
									.signOut()
									.then(() => {
										setUserInfo(null);
									})
							}
						>
							log out
						</li>
					</ul>
				)}
			</div>
		</HeaderContainer>
	);
};

export default Header;

const HeaderContainer = styled.header`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #6bbdb8;
	border-bottom: 1px solid #bdbdbd;
	.icon-container {
		display: flex;
		align-items: center;
		margin-right: 55px;
		color: #2c141a;
		text-decoration: none;
		svg {
			width: 33px;
		}
	}
	.input-container {
		width: 337px;
		height: 26px;
		display: flex;
		align-items: center;
		padding: 0 2px 0 12px;
		background: #f2f2f2;
		border: 1px solid #e0e0e0;
		box-sizing: border-box;
		border-radius: 20px;
		margin-right: 28px;
		input {
			background: #f2f2f2;
			flex: 1;
			border: none;
		}
	}
	.profile-photo {
		width: 32px;
		height: 32px;
		position: relative;
		cursor: pointer;
		img {
			width: 100%;
			height: 100%;
			border-radius: 32px;
		}
		.popup {
			width: 100px;
			position: absolute;
			border: 1px solid;
			border-radius: 3px;
			background-color: #fff;
			top: 32px;
			z-index: 2;
			li {
				padding: 5px;
				&:hover {
					cursor: pointer;
					background-color: gray;
				}
			}
		}
	}
	.snoo-categories {
		height: 100%;
		display: flex;
		font-family: Helvetica Neue;
		font-size: 17px;
		color: #2c141a;
		button {
			height: 100%;
			padding: 0 30px;
			&.selected {
				border-bottom: 4px solid #2c141a;
			}
		}
	}
`;
