import { FC, ReactNode } from 'react';
import AuthProvider from 'Auth/AuthProvider';
import SnooProvider from 'Snoo/SnooProvider';

const composeProviders = (...providers: any) => {
	const { length } = providers;
	if (length === 1) {
		const Provider = providers[0];
		return (props: any) => <Provider {...props}>{props.children}</Provider>;
	}

	return providers.reduce(
		(
			ProviderA: FC<{ children?: ReactNode }>,
			ProviderB: FC<{ children?: ReactNode }>
		) => (props: { children: any }) => (
			<ProviderA {...props}>
				<ProviderB {...props}>{props.children}</ProviderB>
			</ProviderA>
		)
	);
};

const Providers = composeProviders(AuthProvider, SnooProvider);

export default Providers;
