import {
	FC,
	ReactNode,
	createContext,
	useContext,
	useMemo,
	useReducer,
} from 'react';
import { ServerSnoo, Snoo } from './snooTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { snooDateToDateTime } from 'utils';
import { DateTime } from 'luxon';

type SnooContextState = {
	snoosById: { [id: string]: Snoo };
	actions?: {
		fetchSnoosByUserId: (userId: string) => any;
		fetchSnooById: (snooId: string) => any;
	};
};

const initialState: SnooContextState = { snoosById: {} };

const SnooContext = createContext<SnooContextState>(initialState);

const SnooProvider: FC<{ children?: ReactNode }> = (props) => {
	const [state, dispatch] = useReducer(snooReducer, initialState);

	const actions = useMemo(
		() => ({
			fetchSnoosByUserId: (userId: string) => {
				firebase
					.firestore()
					.collection('snoos')
					.where(`owners.${userId}`, '==', true)
					.withConverter(snooConverter)
					.onSnapshot((querySnapshot) => {
						querySnapshot.docChanges().forEach((change) => {
							if (['added', 'modified'].includes(change.type)) {
								const data = change.doc.data();
								if (data.imageURL) {
									firebase
										.storage()
										.refFromURL(data.imageURL)
										.getDownloadURL()
										.then((imageURL: string) => {
											dispatch({
												type: ActionTypes.SET_SNOO,
												snoo: {
													id: change.doc.id,
													name: data.name,
													imageURL,
													ownerIds: Object.keys(data.owners),
													description: data.description,
													date: data.selectedDate ? snooDateToDateTime(data.selectedDate) : DateTime.fromSeconds(data.date),
													TBD: data.TBD,
													time: data.time,
													where: data.where,
													dateCreated: DateTime.fromSeconds(data.date),
													rsvp: data.rsvp || false,
												},
											});
										});
								} else {
									dispatch({
										type: ActionTypes.SET_SNOO,
										snoo: {
											id: change.doc.id,
											name: data.name,
											...(data.imageURL && { imageURL: data.imageURL }),
											ownerIds: Object.keys(data.owners),
											description: data.description,
											date: data.selectedDate ? snooDateToDateTime(data.selectedDate) : DateTime.fromSeconds(data.date),
											TBD: data.TBD,
											time: data.time,
											where: data.where,
											dateCreated: DateTime.fromSeconds(data.date),
											rsvp: data.rsvp || false,
										},
									});
								}
							} else if (change.type === 'removed') {
							}
						});
					});
			},
			fetchSnooById: (snooId: string) => {
				firebase
					.firestore()
					.collection('snoos')
					.doc(snooId)
					.withConverter(snooConverter)
					.onSnapshot((querySnapshot) => {
						const data = querySnapshot.data();
						if (!data) return;
						if (data.imageURL) {
							firebase
								.storage()
								.refFromURL(data.imageURL)
								.getDownloadURL()
								.then((imageURL: string) => {
									dispatch({
										type: ActionTypes.SET_SNOO,
										snoo: {
											id: snooId,
											name: data.name,
											imageURL,
											ownerIds: Object.keys(data.owners),
											description: data.description,
											date: snooDateToDateTime(data.selectedDate),
											TBD: data.TBD,
											time: data.time,
											where: data.where,
											dateCreated: DateTime.fromSeconds(data.date),
											rsvp: data.rsvp || false,
										},
									});
								});
						} else {
							dispatch({
								type: ActionTypes.SET_SNOO,
								snoo: {
									id: snooId,
									name: data.name,
									...(data.imageURL && { imageURL: data.imageURL }),
									ownerIds: Object.keys(data.owners),
									description: data.description,
									date: snooDateToDateTime(data.selectedDate),
									TBD: data.TBD,
									time: data.time,
									where: data.where,
									dateCreated: DateTime.fromSeconds(data.date),
									rsvp: data.rsvp || false,
								},
							});
						}
					});
			},
		}),
		[]
	);

	return (
		<SnooContext.Provider value={{ ...state, actions }}>
			{props.children}
		</SnooContext.Provider>
	);
};

export default SnooProvider;

export const useSnooState = () => {
	const context = useContext(SnooContext);
	if (context == null) {
		throw new Error('useSnooState must be used with a SnooProvider');
	}

	return context;
};

type SetSnooLiteral = '@@snoo/SET_SNOO';

const ActionTypes = { SET_SNOO: '@@snoo/SET_SNOO' as SetSnooLiteral };

type SnooAction = { type: SetSnooLiteral; snoo: Snoo };

const snooReducer = (draft: SnooContextState, snooAction: SnooAction) => {
	switch (snooAction.type) {
		case ActionTypes.SET_SNOO:
			return {
				...draft,
				snoosById: {
					...draft.snoosById,
					[snooAction.snoo.id]: snooAction.snoo,
				},
			};
		default:
			return draft;
	}
};

const snooConverter = {
	toFirestore: (snoo: ServerSnoo): firebase.firestore.DocumentData => ({
		...snoo,
	}),
	fromFirestore: (
		snapshot: firebase.firestore.QueryDocumentSnapshot,
		options: firebase.firestore.SnapshotOptions
	): ServerSnoo => snapshot.data(options) as ServerSnoo,
};
