import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSnooState } from 'Snoo/SnooProvider';
import { DateTime } from 'luxon';
import { ReactComponent as Clock } from 'icons/clock.svg';
import { ReactComponent as Location } from 'icons/location.svg';
import { ReactComponent as Pencil } from 'icons/pencil.svg';
import { useAuthState } from 'Auth/AuthProvider';

const currentDate = DateTime.local().startOf('day');

const SingleSnoo = () => {
	const { actions: snooActions, snoosById } = useSnooState();
	const { otherProfilesById, userInfo } = useAuthState();
	const { snooId } = useParams<{ snooId: string }>();
	useEffect(() => {
		if (snooActions) {
			snooActions.fetchSnooById(snooId);
		}
	}, [!!snooActions]);
	const snoo = snoosById[snooId];
	const snooDateText = useMemo(() => {
		if (!snoo?.date) return '';
		const firstPart = `${snoo.date.weekdayLong} ${snoo.date.toLocaleString(
			DateTime.DATE_FULL
		)}`;
		const diff = snoo.date.diff(currentDate, 'day').days;
		const secondPart =
			diff === 0
				? 'today'
				: `${Math.abs(diff)} day${Math.abs(diff) !== 1 ? 's' : ''} ${
						diff > 0 ? 'away' : 'ago'
				  }`;
		return (
			<div className="date">
				<div>{firstPart}</div>
				<div className="dot">•</div>
				<div className="comparison">{secondPart}</div>
			</div>
		);
	}, [snoo?.date]);
	if (!snoo) return <div>LOADING...</div>;

	return (
		<SingleSnooContainer>
			<Panel className="first">
				{snoo.imageURL && <img src={snoo.imageURL} />}
				<div className="top-content">
					<div className="name-and-date">
						<div className="name">{snoo.name}</div>
						<div>{!snoo.TBD ? snooDateText : ''}</div>
					</div>
					<div className="description">{snoo.description}</div>
					<div className="time-and-location-and-rsvp">
						<div className="time-and-location">
							<div className="time">
								<Clock /> {snoo.time}
							</div>
							<div className="location">
								<Location /> {snoo.where}
							</div>
						</div>
						{snoo.rsvp && (
							<div className="rsvp">
								<div className="can-you-go">can you go?</div>
								<button className="yes">✓</button>
								<button className="no">✗</button>
								<button className="idk">?</button>
							</div>
						)}
					</div>
				</div>
				<button className="edit">
					<Pencil />
				</button>
			</Panel>
			<Panel className="second">
				<div>
					<span>invited snoos </span>
					{snoo.rsvp && <span>• ? going, ? maybe</span>}
				</div>
				<ul className="invited-snoos">
					{snoo.ownerIds
						.filter((id) => id !== userInfo?.id)
						.map((id) => {
							const { imageUrl, name } = otherProfilesById[id];
							return (
								<li key={id}>
									<img alt={name} src={imageUrl} />
								</li>
							);
						})}
				</ul>
			</Panel>
			{/* TODO: implement comments
            <Panel>
            <ul></ul>
            <div>
                <input />
            </div>
        </Panel> */}
		</SingleSnooContainer>
	);
};

export default SingleSnoo;

const Panel = styled.div`
	padding: 20px;
	position: relative;
	background: #fff;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	&.first {
		padding: 0 0 20px;
	}
	margin-bottom: 8px;
`;

const SingleSnooContainer = styled.div`
	width: 770px;
	.first {
		img {
			width: 100%;
			height: 310px;
			object-fit: cover;
			border-top: 1px solid #bdbdbd;
			border-bottom: 1px solid #bdbdbd;
			margin-bottom: 10px;
		}
		.top-content {
			padding: 0 20px;
			.name-and-date {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #e0e0e0;
				margin-bottom: 10px;
				.name {
					font-size: 32px;
					font-weight: 500;
					font-style: italic;
					font-family: EB Garamond;
				}
				.date {
					display: flex;
					font-family: Helvetica Neue;
					font-size: 16px;
					.dot {
						margin: 0 5px;
					}
					.comparison {
						color: #828282;
					}
				}
			}
			.description {
				margin-bottom: 20px;
				font-family: Helvetica Neue;
			}
			.time-and-location-and-rsvp {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.time-and-location {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					.time,
					.location {
						display: flex;
						justify-content: space-between;
						align-items: center;
						svg {
							margin-right: 15px;
						}
					}
					.time {
						margin-bottom: 15px;
					}
				}
				.rsvp {
					width: 240px;
					height: 44px;
					background: #f2f2f2;
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
					border-radius: 5px;
					display: flex;
					align-items: center;
					> * {
						height: 100%;
						display: flex;
						align-items: center;
						color: #2c5d5a;
						border-right: 1px solid #e0e0e0;
						&:last-child {
							border: none;
						}
					}
					.can-you-go {
						font-family: EB Garamond;
						font-weight: 500;
						padding: 0 10px;
						font-style: italic;
					}
					.yes,
					.no,
					.idk {
						flex: 1;
						display: flex;
						justify-content: center;
					}
					.yes {
						font-family: Hiragino Kaku Gothic Pro;
						font-size: 32px;
					}
					.no {
						font-family: Meiryo;
						font-size: 28px;
					}
					.idk {
						font-family: Geneva;
						font-size: 30px;
					}
				}
			}
		}
		.edit {
			width: 52px;
			height: 52px;
			position: absolute;
			top: 273px;
			right: 20px;
			background: #6bbdb8;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.59);
			border-radius: 50%;
		}
	}
	.second {
		.invited-snoos {
			display: flex;
			gap: 10px;
			img {
				width: 40px;
				height: 40px;
				border-radius: 40px;
			}
		}
	}
`;
