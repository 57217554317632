import { DateTime } from 'luxon';

export const snooDateToDateTime = (dateString: string): DateTime => {
	const year = parseInt(dateString.slice(0, 4));
	const month = parseInt(dateString.slice(4, 6));
	const day = parseInt(dateString.slice(6));
	if ([year, month, day].some((n) => isNaN(n))) {
		throw new Error('invalid snoo date!');
	}
	return DateTime.local(year, month, day);
};

export const dateTimeToSnooDate = (dateTime: DateTime): string =>
	dateTime.toFormat('yyyyLLdd');
