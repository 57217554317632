import {
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';
import { Profile, UserCreds, UserInfo } from './authTypes';
import * as authApi from './authApi';
import firebase from 'firebase';

type AuthContextState = {
	actions?: {
		signIn: (creds: UserCreds) => Promise<firebase.auth.UserCredential>;
	};
	otherProfilesById: { [id: string]: Profile };
	setOtherProfilesById?: Dispatch<SetStateAction<{ [id: string]: Profile }>>;
	setUserInfo?: Dispatch<SetStateAction<UserInfo | null>>;
	userInfo: UserInfo | null;
};

const AuthContext = createContext<AuthContextState>({
	otherProfilesById: {},
	userInfo: null,
});

const AuthProvider: FC<{ children?: ReactNode }> = (props) => {
	const [otherProfilesById, setOtherProfilesById] = useState<{
		[id: string]: Profile;
	}>({});
	const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

	const actions = useMemo(
		() => ({ signIn: (creds: UserCreds) => authApi.signIn$(creds) }),
		[]
	);

	return (
		<AuthContext.Provider
			value={{
				actions,
				otherProfilesById,
				setOtherProfilesById,
				setUserInfo,
				userInfo,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

export const useAuthState = () => {
	const context = useContext(AuthContext);
	if (context == null) {
		throw new Error('useAuthState must be used with a AuthProvider');
	}

	return context;
};
