import { useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import Autosizer from 'react-virtualized-auto-sizer';
import { Snoo, SnooCategory } from './snooTypes';
import styled from 'styled-components';
import SnooListRow from './SnooListRow';

type Props = {
	selectedCategory: SnooCategory;
	snoos: Snoo[];
};

const SnooList = ({ selectedCategory, snoos }: Props) => {
	const snoosByDate = snoos.reduce<{ [date: number]: Snoo[] }>((acc, snoo) => {
		const snooTimeStamp = snoo.TBD
			? snoo.dateCreated.toSeconds()
			: snoo.date.toSeconds();
		return {
			...acc,
			[snooTimeStamp]: acc[snooTimeStamp]
				? [...acc[snooTimeStamp], snoo]
				: [snoo],
		};
	}, {});
	const snooDates = useMemo(
		() =>
			Object.keys(snoosByDate)
				.map((date) => parseInt(date))
				.sort((d1, d2) => {
					switch (selectedCategory) {
						case 'upcoming':
							return d1 - d2;
						case 'past':
							return d2 - d1;
						case 'TBD':
						default:
							return 0;
					}
				}),
		[snoosByDate, selectedCategory]
	);

	const isFirstInMonth = useMemo(
		() => (index: number) => {
			return index === 0;
		},
		[snooDates]
	);

	const getRowSize = (index: number) => {
		const date = snooDates[index];
		const headerHeight =
			selectedCategory !== 'TBD' && isFirstInMonth(index) ? 48 : 0;
		return snoosByDate[date].length * 100 + headerHeight;
	};

	return (
		<SnooListContainer>
			<Autosizer>
				{({ height, width }) => (
					<List
						height={height}
						width={width}
						itemSize={getRowSize}
						itemCount={snooDates.length}
					>
						{({ index }) => (
							<SnooListRow
								index={index}
								isFirstInMonth={isFirstInMonth}
								selectedCategory={selectedCategory}
								snoosByDate={snoosByDate}
								snooDates={snooDates}
							/>
						)}
					</List>
				)}
			</Autosizer>
		</SnooListContainer>
	);
};

export default SnooList;

const SnooListContainer = styled.div`
	overflow-y: scroll;
	height: calc(100% - 32px);
`;
