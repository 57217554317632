import { FC, useEffect, useState } from 'react';
import UserLogin from 'Auth/UserLogin';
import firebase from 'firebase/app';
import 'firebase/database';
import { useAuthState } from 'Auth/AuthProvider';
import Providers from 'Providers';
import App from './App';
import { friendStatusConversionMap } from 'Auth/authTypes';

const Root: FC = () => {
	const { setOtherProfilesById, userInfo, setUserInfo } = useAuthState();
	if (setOtherProfilesById == null || setUserInfo == null) {
		throw Error('useAuthState must be used within an AuthProvider');
	}

	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user && user.uid !== userInfo?.id) {
				setLoading(true);
				firebase
					.database()
					.ref(`profiles/${user.uid}`)
					.once('value')
					.then((snapshot) => {
						const userData = snapshot.val();
						setUserInfo({ ...userData, id: user.uid });
						if (userData.imageURL) {
							firebase
								.storage()
								.refFromURL(userData.imageURL)
								.getDownloadURL()
								.then((imageUrl: string) => {
									setUserInfo((currentInfo) =>
										currentInfo
											? {
													...currentInfo,
													...(imageUrl && { imageUrl }),
											  }
											: null
									);
								});
						}
						firebase
							.database()
							.ref('friends')
							.child(user.uid)
							.once('value')
							.then((snapshot) => {
								const friendStatusByProfileId = snapshot.val();
								firebase
									.database()
									.ref()
									.child('profiles')
									.once('value')
									.then((snapshot) => {
										const allServerProfilesById = snapshot.val();
										const allServerProfilesWithImages = Promise.all(
											Object.keys(allServerProfilesById).map((profileId) => {
												const {
													imageURL: serverImageURL,
													...serverProfile
												} = allServerProfilesById[profileId];
												if (serverImageURL) {
													return firebase
														.storage()
														.refFromURL(serverImageURL)
														.getDownloadURL()
														.then((imageURL) => {
															return {
																...serverProfile,
																id: profileId,
																imageURL,
															};
														});
												} else {
													return Promise.resolve({
														...serverProfile,
														id: profileId,
													});
												}
											})
										);
										allServerProfilesWithImages.then((allServerProfiles) => {
											setOtherProfilesById(
												allServerProfiles.reduce(
													(acc, { id, imageURL, ...serverProfile }) => {
														return {
															...acc,
															[id]: {
																...serverProfile,
																imageUrl: imageURL,
																friendStatus:
																	friendStatusConversionMap[
																		friendStatusByProfileId[id]
																	],
															},
														};
													},
													{}
												)
											);
										});
										setLoading(false);
									});
							});
					});
			} else {
				setLoading(false);
			}
		});
	}, []);
	if (loading) return <div>LOADING</div>;
	return userInfo ? <App /> : <UserLogin />;
};

const RootContainer: React.FC = () => {
	return (
		<Providers>
			<Root />
		</Providers>
	);
};

export default RootContainer;
