import { Reducer, useReducer } from 'react';
import styled from 'styled-components';
import { useAuthState } from './AuthProvider';
import { ReactComponent as ButterflyIcon } from 'icons/butterfly.svg';
import { ReactComponent as GoogleIcon } from 'icons/google.svg';
import firebase from 'firebase/app';
import 'firebase/auth';

type State = { email: string; password: string };
type StateUpdate = { email?: string; password?: string };

const UserLogin = () => {
	const { actions: authActions } = useAuthState();
	if (typeof authActions === 'undefined') {
		throw new Error('useAuthState must be used with an AuthProvider');
	}

	const [state, setState] = useReducer<Reducer<State, StateUpdate>>(
		(prevState, update) => ({ ...prevState, ...update }),
		{
			email: '',
			password: '',
		}
	);
	return (
		<UserLoginContainer>
			<div className="content-container">
				<div className="app-description">
					<div>
						plan <i>snoos</i>
					</div>
					<div>
						connect with <i>snoos</i>
					</div>
					<div>
						remember <i>snoos</i>
					</div>
				</div>
				<div className="sign-in">
					<div className="logo">
						<ButterflyIcon />
						<span>snoo</span>
					</div>
					<button
						className="google-button"
						onClick={() => {
							const provider = new firebase.auth.GoogleAuthProvider();
							provider.addScope('https://www.googleapis.com/auth/plus.login');
							return firebase.auth().signInWithPopup(provider);
						}}
					>
						<GoogleIcon />
						<span>Continue with Google</span>
					</button>
					<div className="or">
						<div className="bar" />
						<p>or</p>
						<div className="bar" />
					</div>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							authActions.signIn(state).then((user) => {});
						}}
					>
						<input
							placeholder="email"
							value={state.email}
							onChange={(e) => setState({ email: e.target.value })}
						/>
						<div className="password-container">
							<input
								placeholder="password"
								type="password"
								value={state.password}
								onChange={(e) => setState({ password: e.target.value })}
							/>
							<button
								type="button"
								onClick={() => {
									console.log('too bad!!!');
								}}
							>
								Forgot password?
							</button>
						</div>
						<button className="log-in-button" type="submit">
							Log In
						</button>
					</form>
					<div className="no-account">
						<span>Don't have an account?</span>
						<button>Create one</button>
					</div>
				</div>
			</div>
			<div className="policies">
				<a>Terms of Service</a>
				<span>•</span>
				<a>Privacy Policy</a>
			</div>
		</UserLoginContainer>
	);
};

export default UserLogin;

const UserLoginContainer = styled.main`
	font-family: EB Garamond;
	.content-container {
		height: 500px;
		display: flex;
		.app-description {
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: #6bbdb8;
			> * {
				margin: 20px 0px 20px 150px;
				color: #fff;
				font-size: 30px;
			}
		}
		.sign-in {
			width: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 60px;
			.logo {
				font-size: 64px;
				font-weight: 500;
				span {
					font-style: italic;
				}
			}
			.google-button {
				width: 218px;
				height: 41px;
				display: flex;
				margin-bottom: 17px;
				align-items: center;
				background-color: #fff;
				border: 1px solid #b9b9b9;
				box-sizing: border-box;
				filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
				font-family: Roboto;
				font-size: 14px;
				color: #787878;
				margin-bottom: 17px;
				svg {
					height: 17px;
					margin: 0 16px 0 10px;
				}
			}
			.or {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #b9b9b9;
				font-family: Roboto;
				font-style: italic;
				font-weight: 500;
				font-size: 13px;
				text-transform: uppercase;
				p {
					margin: 0 3px;
				}
				.bar {
					width: 85px;
					height: 1px;
					background-color: #b9b9b9;
				}
			}
			form {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 20px 0;
				input {
					width: 205px;
					height: 33px;
					border: 1px solid #b9b9b9;
					box-sizing: border-box;
					border-radius: 5px;
					padding: 0 10px;
				}

				.password-container {
					position: relative;
					margin: 15px 0 25px;
					button {
						height: 100%;
						position: absolute;
						width: auto;
						border: none;
						background: inherit;
						color: #6bbdb8;
						font-size: 12px;
						font-family: Roboto;
						left: 215px;
					}
				}
				.log-in-button {
					width: 127px;
					height: 34px;
					border: 1px solid #589a96;
					box-sizing: border-box;
					border-radius: 25px;
					background-color: #fff;
					font-size: 15px;
					font-weight: 700;
					font-family: Roboto;
					color: rgba(0, 0, 0, 0.51);
				}
			}
			.no-account {
				> * {
					font-size: 15px;
					font-family: Roboto;
				}
				button {
					height: 100%;
					color: #6bbdb8;
					text-decoration: underline;
					margin-left: 5px;
				}
			}
		}
	}
	.policies {
		display: flex;
		justify-content: center;
		margin-top: 15px;
		color: #6bbdb8;
		font-family: Roboto;
		font-size: 12px;
		span {
			margin: 0 3px;
		}
	}
`;
