import firebase from 'firebase/app';
import 'firebase/auth';

export async function signIn$({
	email,
	password,
}: {
	email: string;
	password: string;
}) {
	const user = await firebase
		.auth()
		.signInWithEmailAndPassword(email, password);
	return user;
}
