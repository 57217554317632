import { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import styled from 'styled-components';
import HomePage from 'Home/HomePage';
import SingleSnoo from 'Snoo/SingleSnoo';
import Header from 'Header';
import { SnooCategory } from 'Snoo/snooTypes';
import { DateTime, Info } from 'luxon';
import { useAuthState } from 'Auth/AuthProvider';

const currentDate = DateTime.local().startOf('day');

const App = () => {
	const { userInfo, setUserInfo } = useAuthState();
	const [selectedCategory, setSelectedCategory] = useState<SnooCategory>(
		'upcoming'
	);
	const [selectedDate, setSelectedDate] = useState<DateTime>(currentDate);
	const [navYear, setNavYear] = useState<number>(currentDate.year);

	useEffect(() => {
		setSelectedDate(currentDate);
		setNavYear(currentDate.year);
	}, [selectedCategory]);

	return (
		<Router>
			<Header
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
			/>
			<Main>
				<div className="sidebar-left">
					<div className="profile-container">
						<img src={userInfo?.imageUrl} alt="profile" />
						<span className="name">{userInfo?.name}</span>
					</div>
					<div className="line" />
					<button>
						<div className="plus">+</div>
						<div className="event">snoo event</div>
					</button>
				</div>
				<Switch>
					<Route path="/snoos/:snooId" component={SingleSnoo} />
					<Route
						path="/"
						component={() => (
							<HomePage
								selectedCategory={selectedCategory}
								selectedDate={selectedDate}
							/>
						)}
					/>
					<Redirect to="/" />
				</Switch>
				<div className="sidebar-right">
					<div className="arrows">
						<button
							disabled={
								selectedCategory === 'TBD' ||
								(selectedCategory === 'upcoming' &&
									navYear === currentDate.year)
							}
							onClick={() => setNavYear(navYear - 1)}
						>
							{'<'}
						</button>
						<span className="year">{navYear}</span>
						<button
							disabled={
								selectedCategory === 'TBD' ||
								(selectedCategory === 'past' && navYear === currentDate.year)
							}
							onClick={() => setNavYear(navYear + 1)}
						>
							{'>'}
						</button>
					</div>
					<ul>
						{Info.months().map((month, idx) => {
							return (
								<li key={idx}>
									<button
										className={
											selectedCategory !== 'TBD' &&
											selectedDate.year === navYear &&
											selectedDate.month === idx + 1
												? 'active'
												: ''
										}
										disabled={
											selectedCategory === 'TBD' ||
											(selectedCategory === 'upcoming' &&
												DateTime.local(navYear, idx + 1) <
													currentDate.startOf('month')) ||
											(selectedCategory === 'past' &&
												DateTime.local(navYear, idx + 1) >
													currentDate.startOf('month'))
										}
										onClick={() =>
											setSelectedDate(DateTime.local(navYear, idx + 1))
										}
									>
										{month}
									</button>
								</li>
							);
						})}
					</ul>
				</div>
			</Main>
		</Router>
	);
};

export default App;

const Main = styled.main`
	position: relative;
	width: 100%;
	padding: 20px 0 30px;

	height: calc(100vh - 50px);
	display: flex;
	justify-content: center;
	background-color: #f2f2f2;

	.sidebar-left {
		min-width: 190px;
		font-family: Helvetica Neue;
		margin-right: 32px;
		padding-top: 32px;
		.profile-container {
			min-width: 180px;
			display: flex;
			align-items: center;
			padding-bottom: 15px;
			border-bottom: 1px solid #e0e0e0;
			margin-bottom: 20px;
			img {
				width: 50px;
				height: 50px;
				border-radius: 50px;
				margin-right: 5px;
			}
		}
		button {
			width: 150px;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #6bbdb8;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 30px;
			color: #fff;
			.plus {
				font-size: 40px;
				margin-right: 10px;
			}
			.event {
				font-size: 18px;
			}
		}
	}
	.sidebar-right {
		width: 90px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 65px;
		padding-top: 32px;
		.arrows {
			.year {
				font-family: Helvetica Neue;
				font-size: 20px;
				margin: 0 10px;
			}
			button {
				color: #6bbdb8;
				font-family: Helvetica Neue;
				font-size: 20px;
				&:disabled {
					color: #c4c4c4;
				}
			}
		}
		ul {
			li {
				cursor: pointer;
				color: #2c141a;
				margin: 8px 0;
				button {
					font-family: Helvetica Neue;
					font-size: 18px;
					&.active {
						font-weight: 700;
					}
					&:disabled {
						cursor: default;
					}
				}
			}
		}
	}
`;
